

















































import Vue from 'vue';
import data from '../carddata.json';
import card from '../cardv2.vue';

export default Vue.extend({
  name: 'Picked',
  components: { card },
  props: {
    picked: {
      type: Object,
      required: true,
    },
    timeplayed: {
      type: Number,
      default: '',
    },
  },
  computed: {
    mostPickedCards(): any {
      const items: any = [];      
      if (this.picked.mostpicked && this.picked.mostpicked.length > 0) {
        this.picked.mostpicked.forEach((item: any) => {
          let res = data.cards.find((item1: any) => this.itemPureName(item.name) === this.itemPureName(item1.name));
          if (res === undefined) {
            // if not a card from character list show blank card with name
            res = this.createBlankCard(item.name, item.character_chosen);
          }
          const obj: any = {};
          console.log('item', item);

          obj.card = res;
          obj.calc = {
            picked: item.picked,
            notpicked: item.notpicked,
            pickrate: item.pickrate,
          };
          items.push(obj);
        });
      }
      return items;
    },
    leastPickedCards(): any {
      const items: any = [];
      if (this.picked.leastpicked && this.picked.leastpicked.length > 0) {
        this.picked.leastpicked.forEach((item: any) => {
          console.log('item', item);
          
          let res = data.cards.find((item1: any) => this.itemPureName(item.name) === this.itemPureName(item1.name));
          if (res === undefined) {
            // if not a card from character list show blank card with name
            res = this.createBlankCard(item.name, item.character_chosen);
          }
          const obj: any = {};
          obj.card = res;
          obj.calc = {
            picked: item.picked,
            notpicked: item.notpicked,
            pickrate: item.pickrate,
          };
          items.push(obj);
        });
      }
      return items;
    },
  },
  methods: {
    itemPureName(name: string) {
      return name.replace(/\s/g, '').replace(/\'/g, '').replace(/\-/g, '').toLowerCase();
    },
    createBlankCard(cardName: string, character: string): any {
      return {
        name: cardName,
        character: 'curse',
        type: 'skill',
        description: `Unknown mod card`,
        descriptionplus: `Unknown mod card`,
        tags: [],
        mana: -2,
        manaplus: -2,
        rarity: 'common',
        tier: 'U',
        supersynergies: [],
        synergies: [],
        antisynergies: [],
        statement: '',
        ratios: [],
        index: 0,
        beta: false,
        mod: true,
      };
    },
  },
});
