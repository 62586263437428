







































import Vue from 'vue';
import gql from 'graphql-tag';
import picked from './mostPicked.vue';

export default Vue.extend({
  name: 'profile',
  components: { picked },
  data() {
    return {
        picked: {},
        user: {},
        characters: [
          'all',
          'ironclad',
          'silent',
        ],
        timeplayed: 0,
    };
  },
  methods: {
    async getData() {
      const res = await this.$apollo.query({
        query: gql`query($username: String!) {
          getUser(username: $username) {
            user {
              username
              runs {
                id
              }
            }
            picked {
              mostpicked {
                name
                picked
                notpicked
                pickrate
              }
              leastpicked {
                name
                picked
                notpicked
                pickrate
              }
            }
            # timeplayed
            # gamestotal
            # gameswon
            # gameslost
          }
        }`,
        variables: {
          username: this.$route.params.username,
        },
      });
      this.picked = res.data.getUser.picked;
      this.user = res.data.getUser.user;
      // this.timeplayed = res.data.getUser.timeplayed / 60 / 24;
    },
  },
  created() {
    this.getData();
  },
  // mounted() {
  //   this.getUserData();
  // }
});
